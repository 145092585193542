/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ main container ~~~~~~~~~~~~~~~~~~~~~~~~ */

.ScanContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.ScanContainer h4 {
  font-size: 18px;
  color: #7b7b7b;
  margin-bottom: 24px;
  cursor: pointer;
  z-index: 10000;
}

.detailsContainer {
  display: flex;
  margin-left: 40px;
  /* margin-top: 20px; */
}

.uploadCount {
  display: flex;
  width: 50%;
  flex-direction: column;
}

.infoCountTitle {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 90vh;
  justify-content: space-between;
}

.imageStyle {
  height: 96px;
  width: 96px;
  border-radius: 50%;
}

.MuiSvgIcon-root.editIcon{
  position: relative;
  color: #1c2124;
  top: 30px;
  right: 31px;
  font-size: 27px;
  cursor: pointer;
}

.profileMain {
  margin-top: 23px;
  width: 80%;
}

.fullNameInput {
  outline: none;
  border: none;
  width: 70%;
  font-size: 18px;
  /* margin-top: -3%; */
  background-color: #fafafa;
}

.fullName {
  margin-bottom: 0px;
  font-size: 18px;
  width: 45%;
}

.emailInput {
  outline: none;
  border: none;
  width: 70%;
  font-size: 18px;
  /* margin-top: -3%; */
  background-color: #fafafa;
}

.passwordInput {
  outline: none;
  border: none;
  width: 70%;
  margin-top: -3%;
  background-color: #fafafa;
}

.changePass {
  /* display: flex;
    justify-content: center;
    align-items: center; */
  font-size: 14px;
  color: #255FFF;
  cursor: pointer;
  margin-top: 23px;
  margin-bottom: 23px;
  margin-left: 31.5%;
  width: 80%;
}

.profileSame {
  display: flex;
  align-items: center;
  /* margin-top: 3%; */
}

.same {
  margin-top: 5%;
}

.font_weight {
  font-weight: 500;
}

.fnameInputs {
  width: 98%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  padding-left: 3%;
}
.fnameInputs:focus {
  outline: #255FFF;
  border: 2px solid #255FFF;
}
/* MEDIA QUERY FOR PROFILW PAGE */
/* Profile Page */

@media (max-width: 1100px) {
  .uploadCount {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .uploadCount {
    width: 100%;
  }
  .fullNameInput {
    font-size: 14px;
  }
  .emailInput {
    font-size: 14px;
  }
  .fullName {
    font-size: 14px;
  }
  .changePass {
    font-size: 10px;
  }
  .detailsContainer {
    margin-left: 35px;
  }
}

@media (max-width: 440px) {
  .uploadCount {
    width: 100%;
  }
  .fullNameInput {
    font-size: 11px;
  }
  .emailInput {
    font-size: 11px;
  }
  .fullName {
    font-size: 11px;
  }
}

/* @media (max-width: 1307px){
    .uploadCount{
        width: 60%;
    }

} */

/* @media (max-width: 1100px){
    .uploadCount{
        width: 80%;
    }

} */

/* @media (max-width: 1000px){
    .uploadCount{
        width: 80%;
    }
    .profileSame{
        flex-direction: column;
    }

    .fullName{
        width: 60%;
        font-size: 13px;
    }
    .emailInput{
        font-size: 14px;
    }
    .fullNameInput{
        font-size: 14px;
    }

} */

/* @media (max-width: 600px){
    .uploadCount{
        width: 100%;
    }
    .emailInput{
        width: 100%;
    }

} */
/* 
@media (max-width: 470px){
    .uploadCount{
        width: 100%;
    }
    .emailInput{
        width: 160%;
    }

} */
/* MEDIA QUERY FOR CHANGE PASSWORD PAGE */
/* Change password */

@media (max-width: 470px) {
  .font_weight {
    font-size: 11px;
  }
}

@media (max-width: 380px) {
  .buttonMain {
    font-size: 15px;
  }
}

@media (max-width: 550px) {
  .profileMain {
    margin-left: 0;
  }
  #changepass {
    margin-left: 0 !important;
  }
}

/* #changepass{
    margin-left:12% ;
    margin-right: 2%;
    margin-top: 6%;
  
} */
