.imageSection {
  /* display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center; */
  /* width: 600px; */
  width: 84%;
  /* margin-top: 32px; */
  /* background-color: blue; */
  /* grid-gap: 30px; */
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.displayedImage {
  margin: 20px;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  text-align: center;
}

.removeImg {
  position: relative;
  top: -66px;
  right: -51px;
  font-size: 25px;
  cursor: pointer;
  opacity: 0;
  /* display: none; */
}

.imageDiv:hover .displayedImage {
  opacity: 0.6;
}

.imageDiv:hover .removeImg {
  opacity: 10;
  /* display: block; */
  color: black;
}

/* @media (max-width: 1100px) {
  .imageSection {
    width: 400px;
  }
} */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Video upload ~~~~~~~~~~~~~~~~~~~~~~~~~~` */
.dropzone {
  border: 2px dashed rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 50px 0;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
}
.dropzone.active {
  border-color: #fff;
}
.file {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  background-color: #41415d;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
  position: relative;
  overflow: hidden;
}
.file .progressBar {
  background-color: rgba(80, 80, 120, 0.8);
  position: absolute;
  inset: 0;
  width: 0;
  padding-top: 10px;
  text-align: center;
}
.file .progressBar.done {
  top: 100px;
}

/* image and video upload */
.allScanContainer {
  display: flex;
  flex-direction: column;
  /* width: 100vw; */
}
.allScanContainer h4 {
  /* font-size: 18px;
  color: #7b7b7b;
  margin-bottom: 24px;
  cursor: pointer;
  z-index: 10000; */
}
.detailsContainerUpload {
  display: flex;
  justify-content: space-between;
  /* margin-top: -24px; */
}
.uploadCont {
  display: flex;
  width: 70%;
  flex-direction: column;
}
.infoContTitle {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: white;
  height: 94vh;
  justify-content: space-between;
  padding: 8px 12px;
  margin-top: -59px;
  margin-right: -13px;
}
.infoCont h4 {
  color: #484848;
}
.title {
  font-size: 12px;
  font-weight: 600;
  color: #4a4a4a;
  padding: 12px;
  margin: 0;
}
.discription {
  color: #7b7b7b;
  font-size: 12px;
  color: #4a4a4a;
  padding: 12px;
  border: none;
  width: 100%;
}

.discription:focus {
  outline: none;
}

.signUpText {
  margin: 0px 12px;
  color: #255FFF;
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
}

.twoButton {
  position: relative;
  display: flex;
  justify-content: space-between;
  bottom: 5%;
  /* top:71%; */
}

.uploadCircle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px dashed #255FFF;
  background-color: #C6D5FF;
}
.addIcon {
  margin-left: 17px;
  margin-top: 17px;
  color: #255FFF;
}

.imageType {
  margin-top: 8px;
  font-size: 14px;
  text-align: center;
  color: #7b7b7b;
}
.dragDrop {
  margin-top: 14px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #4a4a4a;
}
.dropZone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
}

/* ~~~~~~~~~~~~~~~~~~ media query ~~~~~~~~~~~ */

@media (max-width: 1200px) {
  .imageSection {
    /* grid-gap: 30px; */
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (max-width: 1000px) {
  .detailsContainerUpload {
    flex-direction: column;
  }
  .uploadCont {
    width: 100%;
  }
  .infoContTitle {
    width: 100%;
    height: auto;
    padding: 14px 13px;
    margin-top: 0px;
    margin-right: 0px;
  }
  .allScanContainer h4 {
    /* display: none; */
  }
}

@media (max-width: 626px) {
  .dragDrop {
    font-size: 14px;
  }
  .uploadCircle {
    width: 40px;
    height: 40px;
  }
  .addIcon {
    margin-left: 7px;
    margin-top: 6px;
    color: #255FFF;
  }
  .imageType {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .imageSection {
    /* grid-gap: 30px; */
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
