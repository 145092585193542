.footerCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background-color: #2b3635;
  color: #fafafa;
  padding: 0px 30px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~  Copy Right Cont ~~~~~~~~~~~~~~~~```` */

.copyRightCont h4 {
  font-size: 12px;
  margin-bottom: 0px;
  color: #fafafa;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~ Powered Cont ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.poweredCont {
  display: flex;
  margin-left: 200px;
  justify-content: center;
  align-items: center;
}

.poweredCont p {
  margin-right: 14px;
  font-size: 12px;
  margin-bottom: 0px;
}

.poweredCont img {
  width: 39%;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~  Route Cont ~~~~~~~~~~~~~~~~~~~~~~~~~ */

.routeCont {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.routeCont h4 {
  font-size: 12px;
  margin: 0px 7px;
  color: #fafafa;
  cursor: pointer;
}
.routeCont img {
  width: 96%;
  cursor: pointer;
  margin: 0px 7px;
}

.linkSec {
  display: none;
  /* padding:0px 13px */
}

.footerStoreLink {
  display: none;
}

@media (max-width: 1160px) {
  .footerCont {
    flex-direction: column;
    align-items: flex-start;
    height: 210px;
    padding: 15px 30px;
  }

  .poweredCont {
    margin-left: 0px;
    justify-content: flex-start;
  }
  .routeCont h4 {
    margin: 0px;
    margin-right: 7px;
  }
  .routeCont {
    flex-direction: column;
    align-items: flex-start;
    height: 80px;
  }
  .linkSec {
    display: flex;
    padding: 0px 13px;
  }

  .footerStoreLink {
    display: block;
    width: 70%;
    margin-left: -12px;
  }
  .copyRightCont h4 {
    font-size: 18px;
  }

  /* .cardSlider{
    display: none;
  } */
}

@media (max-width: 800px) {
}
