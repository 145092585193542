.ScanContainerss {
    display: flex;
    flex-direction: column;
    padding: 24px;
  }
  
  .ScanContainerss h4 {
    margin: -7px 0px;
  font-size: 14px;
    margin-bottom: 24px;
    cursor: pointer;
    z-index: 10000;
    color: #4a4a4a;
    /* margin-left: 3%;
    margin-top: 2%; */
  }

  .tutorialContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}

.tutDetails{
  width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.tutInfo{
  margin-top: 30px;
  text-align: center;
  width: 80%;
}

.tutDetails video{
  width: 40vw;
}

.uploadCountss{
    /* grid-gap: 32px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  width: 65%; */
  justify-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.infoCountTitless{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 90vh;
    justify-content: space-between;
}

.mrT{
  margin-right: 30px;
  margin-bottom: 30px;
    /* margin-top: 3% !important; */
    width: 417px;
}


@media (max-width:1200px){
  .tutDetails video{
    width: 50vw;
  }
  .tutInfo{
    width: 80%;
  }
}

@media(max-width:1147px){
  .mrT{
    margin-right: 0px;
  }
}


@media (max-width:960px){
  .tutDetails video{
    width: 84vw;
  }
  .tutInfo{
    width: 100%;
  }
}