body {
  background: linear-gradient(
    46deg,
    rgb(37 95 255 / 60%) 10%,
    rgb(37 95 255 / 60%) 100%
  )
}

.forgotpass {
  color: #255FFF;
  text-align: right;
  font-weight: 600;
  cursor: pointer;
}

.googlebtn {
  display: flex;
  align-items: "center";
  justify-content: "center";
  padding: 10px 13px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  
  background-color: white;
  cursor: pointer;
  color: #dd5348;
  font-weight: 600;
  font-size: 15px;

}

/* .googlebtn:hover {
  background-color: #dd5348;
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
} */

.facebookbtn {
  /* display: flex; */
  /* align-items: "baseline"; */
  /* justify-content: "center"; */
  align-items: baseline;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  color: #3b5998;
  background-color: white;
  /* background-color: #3b5998;
  cursor: pointer;
  color: white; */
  font-weight: 600;
  font-size: 15px;
  padding: 10px 15px;
}

/* .facebookbtn:hover {
   background-color: #3b5998;
  cursor: pointer;
   color: white; 
  transition: all 0.2s ease-in-out;
} */

.facebookbtn i {
  margin-right: 9px;
  font-size: 19px;
}

.span {
  color: #5ccda8;
  cursor: pointer;
  font-weight: 600;
}

/* ~~~~~~~~~~~~~~~~~~  Social Media Button ~~~~~~~~~~~~~` */
.buttonDiv {
  width: 400px;
  margin-left: 20px;
  margin-top: 25px;
}

.fbButton {
  margin-left: 4px !important;
  border: 1px solid #c3c0c0 !important;
}

.googleBtn {
  margin-left: 12px !important;
  border: 1px solid #c3c0c0 !important;
  color: black !important;
}

.appleBtn {
  margin-left: 21px !important;
  border: 1px solid #c3c0c0 !important;
  color: black !important;
}

.loginbtncont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 40px;
  width: 100%;
}


@media (max-width:550px){
  .loginbtncont{
    height: 175px;
    flex-direction: column;
  }
}


.appleSignin{
  height: 43px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
}