.logsignCont {
  margin-top: 30px;
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 12px;
}

.homeContainer {
  background-color: white;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~ First Cont ~~~~~~~~~~~~~~~ */

.firstCont {
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
  align-items: center;
}

.firstCont img {
  width: 19%;
  border-radius: 12px;
}
.marginRightImg {
  margin-right: 30px;
}


/* ~~~~~~~~~~~~~~~~~~~~~~ Second Cont ~~~~~~~~~~~~~~ */

.secondCont {
  /* margin-top: 35px; */
  /* padding: 120px 0px; */
  /* padding-bottom: 120px; */
  margin-top: -101px;
}

.secondCont img {
  width: 20%;
  border-radius: 12px;
}

.hutMargin{
  margin-right: -3%;
}

.headingSec {
  text-align: center;
}

.headingSec h3 {
  font-weight: bold;
  font-size: 60px;
}

.headingSec p {
  margin: auto;
  width: 37%;
  font-size: 20px;
  color: #4a4a4a;
}

.secondImageCont {
  display: flex;
  margin-top: 21px;
  justify-content: space-between;
  align-items: center;
}

/* ~~~~~~~~~~~~~~~~~ Video Cont ~~~~~~~~~~~~~~~~~~ */

.videoSec {
  display: flex;
  padding: 120px 0px;
  margin-top: 170px;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
}

.textCont {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 58%;
  justify-content: center;
}

.textCont h3 {
  width: 64%;
  font-size: 40px;
  font-weight: 700;
}

.subHeading {
  margin: 24px 0px;
  width: 64%;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 500;
}

.check {
  margin: 4px 0px;
  width: 64%;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 700;
  list-style-type: none
}

/* .check img {
  margin-right: 10px;
} */

.check li{
  font-size: 16px;
  position: relative;
  margin: 9px 0;
}

.check li::before{
  content: '';
    background: url("../../../assets/image/icons/check.png") ;
    position:absolute;
    top:0px;
    left: -32px;
    width: 20px;
    height: 20px;
}




.videoCont {
  display: flex;
  width: 40%;
  justify-content: end;
}

.videoCont video {
  width: 100%;
  height: 480px;
}

/* ~~~~~~~~~~~~~~~~~~~~~  Testimonials ~~~~~~~~~~~~~~ */

.testimonialsSec {
  text-align: center;
  padding-top: 120px;
  padding-bottom: 20px;
  margin: 0px auto;
}

.testimonialsSec h3 {
  font-size: 32px;
  color: #4a4a4a;
}

.map {
  width: 50%;
  margin: 60px auto;
}

.cardSlider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 80%;
}

.userCard {
  padding: 0px 18px;
  border-radius: 10px;
  /* width: 40%; */
  height: 224px;
  text-align: left;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px rgba(235, 240, 245, 0.69);
  margin: 0px 10px;
}

.country {
  padding: 22px 0px;
  display: flex;
  align-items: center;
}

.country span {
  margin-left: 10px;
  font-size: 18px;
  color: #255FFF;
}

/* ~~~~~~~~~~~~~~~~~~~~~~  slider ~~~~~~~~~~~~~`` */

.sliderControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px auto;
  width: 46%;
}

.points {
  display: flex;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0px 3px;
  background-color: #C6D5FF;
  opacity: 0.24;
}

.active {
  opacity: 1;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~ Get started ~~~~~~~~~~~~~~~~` */

.getStarted {
  text-align: center;
  margin-top: 68px;
  padding: 70px 0px;
  /* background-color: rgba(2, 181, 163, 0.11); */
  background-color: #B8CAFF;
}

.getStarted h3 {
  color: #4a4a4a;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 25px;
}

.getStarted p {
  font-size: 20px;
  font-weight: 600;
  color: #4a4a4a;
  margin-bottom: 25px;
}

@media (max-width: 850px) {
  .getStarted {
    padding: 46px 0px;
  }
  .getStarted h3 {
    font-size: 28px;
  }
  .getStarted p {
    font-size: 14px;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~  media query ~~~~~~~~~~~~~~~~~~~~~~~ */

@media only screen and (max-width: 1050px) {
  .videoSec {
    flex-direction: column-reverse;
  }
  .videoCont {
    width: 100%;
    margin-bottom: 50px;
  }
  .textCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .textCont h3 {
    width: 90%;
  }
  .textCont p {
    width: 90%;
  }
  .check {
    width: 90%;
  }
  .map {
    width: 90%;
  }

  .sliderControls {
    display: none;
  }
}

.alice-carousel__prev-btn {
  text-align: center !important;
}

.item {
  margin-top: 2% !important;
  margin-bottom: 2% !important;
}

@media (max-width: 1450px) {
  .headingSec {
    margin-top: 35px;
  }
  .headingSec h3 {
    font-size: 44px;
  }
}

@media (max-width: 850px) {
  .videoCont video {
    width: 100%;
    height: 340px;
  }
  .imageNone {
    display: none;
  }
  .firstCont img {
    width: 43%;
  }
  .secondCont img {
    width: 45%;
  }
  .hutMargin{
    margin-right: -5%;
  }
  .marginRightImg {
    margin-right: 0px;
  }
  .headingSec {
    margin-top: 130px;
    margin-bottom: 47px;
  }
  .headingSec h3 {
    font-size: 33px;
  }
  .headingSec p {
    width: 90%;
    font-size: 17px;
  }

  .videoSec {
    margin-top: 56px;
    padding: 75px 0px;
  }
  .textCont h3 {
    font-size: 30px;
  }
  .check {
    font-size: 13px;
  }
}


/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ carousel ~~~~~~~~~~~~~~~~~~~~~~~~~ */
.item {
  margin-top: 2% !important;
  margin-bottom: 2% !important;
}

.alice-carousel {
  width: 84% !important;
}

.alice-carousel__dots-item:not(.__custom) {
  background-color: #baf1eb !important;
}

.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #C6D5FF !important;
}

.alice-carousel__dots {
  position: absolute !important;
  right: 12px !important;
  margin: 55px 3px 5px !important;
}

.alice-carousel__dots-item:not(.__custom) {
  width: 13px !important;
  height: 13px !important;
}

.next1 {
  width: 44px;
  position: absolute !important;
  left: 53px !important;
  top: 282px !important;
}

.back1 {
  width: 44px;
  position: absolute !important;
  left: 10px !important;
  top: 282px !important;
}

.getStartedAboutUs{
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: left;

}