.PrivacyDiv{
    display: flex;
    justify-content: center;
    height: 260px;
    background-color: #C6D5FF;
    align-items: center;
      
}

.PrivacyText{
    margin-bottom: 104px;
    font-size: 34px;
    font-weight: bold;
    line-height: 1.62;
    text-align: center;
    color: #fff;
}

.page{
    top: -100px;
    width: 65%;
    position: relative;
    padding: 43px 32px 37px;
    border-radius: 8px;
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 6%);
    border: solid 1px rgba(235, 240, 245, 0.69);
    background-color: #fff;
    margin: 0px auto;
}

.pageText{
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0;
}



.typeNone{
    list-style-type: none;
}


.dummy{
    font-weight: bold;
}


.font{
    font-size: 16px;
}

.answerFaqs{
    font-size: 16px;
    margin-left: 32px;
}

.faqsText{
    font-size: 19px;
    font-weight: bold;
}






@media (max-width: 870px) {
    .PrivacyText{
        /* font-size: 13px; */
        line-height: normal;
    }
    .page{
        width: 92%;
        padding: 24px 14px 20px;
    }
    .pageText{
        font-size: 13px;
    }
    .faqsText {
        font-size: 17px;
    }
    .answerFaqs{
        font-size: 13px;
    }
  }