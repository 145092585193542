/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ main container ~~~~~~~~~~~~~~~~~~~~~~~~ */

.allScanContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
  /* width: 100vw; */
}

.allScanContainer h4 {
  margin: -7px 0px;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 24px;
  /* cursor: pointer; */
  z-index: 10000;
}
.allScanContainer span {
  font-size: 18px;
  color: #4a4a4a;
  margin-bottom: 24px;
  cursor: pointer;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ item Container ~~~~~~~~~~~~~~~~~~~~ */

.itemContainer {
  grid-gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ item card ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.scanItemCard {
  width: 275px;
  height: 317px;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  cursor: pointer;
}

.scanItemCard img {
  /* height: 53%; */
  object-fit: cover;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 155px;
  width: 100%;
}
.scanItemCard video {
  /* height: 53%; */
  height: 155px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.scanItemInfo {
  padding: 5px;
  /* margin-bottom: 0.3rem; */
  padding: 9px 16px;
}

.scanItemInfo h6 {
  font-size: 14px;
  font-weight: 700;
}

.scanDate {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.scanUpload {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0.3rem;
}

.scanInfo {
  font-size: 11px;
}

.scanInfo span {
  font-size: 11px;
  color: #255FFF;
  text-decoration: underline;
}

.viewMore {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.viewMore h4 {
  cursor: pointer;
  text-decoration: underline;
  color: #255FFF;
}



/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Scan Item details below ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.scanDetailsContainer {
  display: flex;
  justify-content: space-between;
}

.modelCont {
  width: 70%;
  height: 85vh;
}

.infoCont {
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: white;
  height: 96vh;
  justify-content: space-between;
  padding: 8px 12px;
  margin-top: -64px;
  margin-right: -22px;
}

.infoCont h4 {
  color: #484848;
}

/* ~~~~~~ info sec ~~~~~~~~~~~~~~ */

.infoSec {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.textSec p {
  font-size: 14px;
  color: #484848;
}

.downloadAsset {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 0px;
  cursor: pointer;
}

.downloadAsset p {
  margin-bottom: 0px;
}

.actionSec {
  padding: 22px 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4a4a4a;
  color: white;
}

.action span {
  color: white;
  margin-bottom: 0px;
  margin-top: 9px;
}

.action:hover {
  cursor: pointer;
  color: #255FFF;
}

.action:hover span {
  color: #255FFF;
}

.action i {
  font-size: 25px;
}

.action {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-header {
  height: 55px;
  background-color: #C6D5FF;
}

.modal-header .btn-close {
  font-size: 13px;
}

.fabButton {
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  background-color: white;
  font-weight: 400;
  cursor: pointer;
  width: 100px;
  color: #7b7b7b;
  font-size: 15px;
  box-shadow: 0 1px 15px 1px rgb(236 236 236);
  margin-left: 11px;
}

.fabButton:hover {
  border: 1px solid #255FFF;
  color: white;
  background-color: #255FFF;
  transition: all 0.2s ease-in-out;
}

.modal-content {
  border-radius: 6px;
  width: 514px;
}

.Mesh {
  font-size: 12px;
  margin-left: 12px;
}

.modalTitle {
  font-size: 18px;
  margin-left: 12px;
  font-weight: bold;
}

.videoDiv {
  margin-top: 10%;
  margin-bottom: 6%;
}

.mainModal {
  display: flex !important;
  align-items: center !important  ;
}

.dotSpan {
  background-color: #000;
  opacity: 0.2;
  border-radius: 4px;
}

.dots {
  color: white;
  opacity: 1;
  margin-top: -5px;
}

.dotSpan:hover {
  background-color: #C6D5FF;
  /* opacity: 0.2; */
}

.dotSpan:hover .dots {
  color: #255FFF;
}

.actionImage {
  font-size: 26px;
}

.labelWrap {
  padding: 0px 3px;
  margin-bottom: 5px;
}
.labelWrap label {
  font-weight: 700;
}

.priorityIcon{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  width: 180px;
}

.priorityIcon h3{
  font-size: 17px;
  margin-bottom: 0px;
}

.priorityIcon img {
  width: 20px;
  height:20px;
  
}

.consentCheckbox{
  display: flex;
  justify-content: flex-start;
  align-items:  flex-start;
  margin-bottom: 20px;
}

.consentCheckbox p{
  font-size: 13px;
  margin-bottom: 0px;
}
.consentCheckbox img{
  cursor: pointer;
  width: 19px;
  height: 17px;
  margin-right: 9px;
  margin-top: 1px;
}



@media (max-width: 1000px) {
  .scanDetailsContainer {
    flex-direction: column;
  }
  .infoCont {
    width: 100%;
    margin-top: 20px;
    margin-right: 0px;
    height: auto;
  }
  .modelCont {
    width: 100%;
    height: 400px;
  }
  .actionImage {
    font-size: 18px;
  }
  .action span {
    font-size: 16px;
  }
}

/* @media (max-width: 800px) {
  .infoCont {
    width: 50%;
  }
  .modelCont {
    width: 50%;
  }
} */

@media (max-width: 625px) {
  .itemContainer {
    margin: auto;
  }
}
