.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  background-color: white;
  border-bottom: 1px solid #dedede;
  padding: 0px 40px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~  Logo section ~~~~~~~~~~~~ */

.logoSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 482px;
}

.playstoreLink {
  width: 42%;
}

.logoSection img {
  width: 178px;
  margin: 0px 5px;
  cursor: pointer;
}

/* ~~~~~~~~~~~~~~~~~~~~~  Right section ~~~~~~~~~~ */
.loginSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signUpText {
  margin: 0px 12px;
  color: #255FFF;
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
}

/* ~~~~~~~~~~~~~~~~~~~  profile ~~~~~~~~~~~~~~~~~~~ */

.userName {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7b7b7b;
  /* width: 72px; */
  cursor: pointer;
}

.userName p {
  margin: 0px 7px;
  font-size: 14px;
  font-weight: 600;
}

.menuProfile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.menuProfile span {
  margin-left: 12px;
  font-size: 12px;
}

@media only screen and (max-width: 800px) {
  .userName p{
    display: none;
  } 
  .downBtn{
    display: none;
  }

  .logoSection{
    width: auto;
  }
  .header {
    padding: 0px 5px;
  }
  .playstoreLink {
    display: none;
  }
}
