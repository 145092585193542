.ScanContainers {
    display: flex;
    flex-direction: column;
    padding: 24px;
    /* width: 100vw; */
  }
  
  .ScanContainers h4 {
    margin: -7px 0px;
  font-size: 14px;
    margin-bottom: 24px;
    cursor: pointer;
    z-index: 10000;
    color: #4a4a4a;
  }
  

  .detailsContainers{
      display: flex;
      
  }

  .uploadCounts{
   
    display: flex;
    width: 40%;
    flex-direction: column;
  
}
  
 
  .infoCountTitles{
    display: flex;
    flex-direction: column;
    width: 60%;
    /* background-color: white; */
    height: 90vh;
    justify-content: space-between;
}

.fnameInput{
    width: 99%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    padding-left: 3%;
}

.fnameInput:focus{
  
    outline: #255FFF;
    border: 2px solid #255FFF;
  
}

.disText{
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    padding-left: 3%;
    width: 99%;
}

.disText:focus{
  
    outline: #255FFF;
    border: 2px solid #255FFF;
  
}

.emailMain{
    /* margin-top: 5%; */
}

.same{
    margin-top: 5%;
}

.font_weight{
    font-weight: 500;
}

@media (max-width: 900px){
  .uploadCounts {
      width: 60%;
  }
}


@media (max-width: 700px){
    .uploadCounts {
        width: 80%;
    }
  }


  @media (max-width: 550px){
    .uploadCounts {
        width: 120%;
    }
  }