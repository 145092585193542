.listSelect{
    padding: 13px 6px;
    display: flex;
    margin: 3px 0px;
    font-size: 17px;
    cursor: pointer;

}

.listSelect:hover{
    background-color: #C6D5FF;
    color: #255FFF;
    
}


.listSelect p{
    margin-bottom: 0px;
    margin-left: 22px;
}

.backgroundColor{
    background-color: #C6D5FF;
    color: #255FFF;
}


.drawerMain{
padding: 0px 11px;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: space-between;
}


.dashImage{
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 14%;
width: 100%;
}

.dashImage img{
    width: 70%;
}

@media (max-width:960px){
    #mobile{
        height: 0px !important;
        margin-top: 2% !important;
    }
}