


.span{
  color: #255FFF;
  cursor: pointer;
  font-weight: 600;
  
}


/* ~~~~~~~~~~~~~~~~~~  Social Media Button ~~~~~~~~~~~~~` */
.buttonDiv{
  width: 400px;
  margin-left: 20px;
  margin-top: 25px;
}

.fbButton{
  margin-left: 4px !important;
  border: 1px solid #c3c0c0 !important;
}

.googleBtn{
  margin-left: 12px !important;
  border: 1px solid #c3c0c0 !important;
  color: black !important;
}

.appleBtn{
  margin-left: 21px !important;
  border: 1px solid #c3c0c0 !important;
  color: black !important;
}
