.notfound {
  display: flex;
  /* height: 600px; */
  height: 87vh;
  justify-content: center;
  padding-top: 100px;
  text-align: center;
}
.text-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.errorTitle {
  font-size: 8em;
  font-weight: 700;
  color: #a71414;
  margin-bottom: 2rem;
  font-family: monospace;
  text-shadow: 0px 0px 5px #1976d2;
}
.subtitle {
  font-size: 20px;
  font-weight: 700;
  color: #1976d2;
  margin-bottom: 2rem;
}
