.pagination {
  padding-top: 1rem;
  text-align: center;
}

.pagination > ul {
  padding: 0;
  margin: 0;
}

.pagination li {
  display: inline-block;
}

.active{
  color: #0d6efd;
}

.pagination li > a {
  text-decoration: none;
  padding: 0.5rem;
  margin: 1px;
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #d8eef5;
  border-radius: 2px;
  min-width: 1rem;
}

.pagination li > a:focus {
  outline: none;
}



.issuesPagination .selected a {
  box-shadow: 0 0 2px #1b7d9e;
  background-color: #f3fcff;
}

.issuesPagination .disabled > a {
  text-decoration: none;
  color: #ccc;
  background-color: #f8f8f8;
  border-color: #eee;
  cursor: default;
}

.issuesPagination .disabled > a:hover {
  background-color: #f8f8f8;
}
.issuesPagination .break {
  margin: 0 8px;
}

.issuesPagination .previous {
  margin-right: 1rem;
}

.issuesPagination .next {
  margin-left: 1rem;
}